import {apiRequest} from '@/utils/axios/axiosInstance.js';
import setPostSlugToURI from '@/utils/post/setPostSlugToURI.js';
import setPublishDateFormat from '@/utils/post/setPublishDateFormat.js';

/**
 * 
 */
const getPostItem = (data) => {
  data.date_publish  = setPublishDateFormat(data.publish);
  data.slug  = setPostSlugToURI( data.slug );

  return apiRequest.post('/content/save-post', data);
}

export default getPostItem;
