// import dateInputToFormat from '@/utils/date/dateInputToFormat.js';
import zeroAfter from '@/utils/number/zeroAfter.js';

const setPublishDateFormat = (publish) => {
  //dateInputToFormat()
  return  publish.date +' '+
          zeroAfter(publish.hour)+':'+
          zeroAfter(publish.min)+':'+
          zeroAfter(publish.sec);
}

export default setPublishDateFormat;